import React from "react";
import { connect } from "react-redux";
import Spinner from "./Spinner";

const mapStateToProps = (state: any) => {
    return {
        activeView: state.activeView
    };
};

class Loading extends React.Component<any, any> {
    render() {
        const isLoading = this.props.activeView === 'Loading'
        if (isLoading) {
            return (
                <Spinner/>
            );
        }
        return this.props.children;
    }
}

export default connect(mapStateToProps, null)(Loading)
