import axios from "axios";
import config from "./config";
import store from "../store";
import { activeLoadingMore, receiveData, updatePage } from "../store/actions";

const fetchFulldiveData = async (next = false) => {
    try {
        const state = store.getState()
        const page = next ? state.page + 1 : 0
        next && store.dispatch(activeLoadingMore(true))
        const data = await axios({
            url: `https://${config.hostname}/v4/resources/by-source/forYou`,
            headers: {
                Authorization: `Bearer ${config.token}`
            },
            params: {
                limit: state.limit,
                skip: page * state.limit
            },
            timeout: 5000
        })
        const resultData = [...state.data || [], ...data.data]
        store.dispatch(receiveData(resultData))
        next && store.dispatch(updatePage(state.page + 1))
    } catch (err) {
        console.error("FETCH ERROR", err)
        // store.dispatch(fetchProductsError())
    }
}

export {
    fetchFulldiveData
}
