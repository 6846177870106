import { ACTIVE_VIEW, LOADING_MORE, RECEIVE_DATA, UPDATE_PAGINATION } from "./action-types";
import { Views } from "../../types";

export function changeActiveView(view: Views) {
    return {
        type: ACTIVE_VIEW,
        view
    }
}

export function receiveData(data: any) {
    return {
        type: RECEIVE_DATA,
        data
    }
}

export function updatePage(page: number) {
    return {
        type: UPDATE_PAGINATION,
        page
    }
}

export function activeLoadingMore(loadingMore: boolean) {
    return {
        type: LOADING_MORE,
        loadingMore
    }
}
