import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from "react-redux"
import store from "./store/index"
import "./startup/appSetup"

const rootNode = document.getElementById('root')

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  rootNode
);
