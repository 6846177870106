import React from 'react';
import { Image } from "react-bootstrap";
import './article.scss'
import { noImage } from "./images"

const Article = (props: any) => {
    const data = props.data
    return (
        <div className="Article">
            <a
                rel="noopener noreferrer"
                href={data.url}
                target="_blank"
            >
                <Image
                    src={noImage}
                    data-src={data.previewUrl}
                    fluid
                    className="preview"
                    alt={data.title}/>
                <div className="title-wrapper">
                <span className="title text-center line-clamp">
                    {data.title}
                </span>
                </div>
            </a>
        </div>
    );
}

export default Article
