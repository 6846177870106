import { ACTIVE_VIEW, LOADING_MORE, RECEIVE_DATA, UPDATE_PAGINATION } from "../actions/action-types";

const initialState = {
    activeView: "Loading",
    limit: 30,
    page: 0,
    data: [],
    loadingMore: false
};

function rootReducer(state = initialState, action: any) {
    if (action.type === ACTIVE_VIEW) {
        return Object.assign({}, state, {
            activeView: action.view
        })
    }

    if (action.type === RECEIVE_DATA) {
        return Object.assign({}, state, {
            data: action.data,
            activeView: "App",
            loadingMore: false
        })
    }

    if (action.type === UPDATE_PAGINATION) {
        return Object.assign({}, state, {
            page: action.page
        })
    }

    if (action.type === LOADING_MORE) {
        return Object.assign({}, state, {
            loadingMore: action.loadingMore
        })
    }

    return state
};

export default rootReducer;
