const dev = {
  hostname: "api-staging.fulldive.com",
  token:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiJ6RFEyTFhuQkE3IiwiaWQiOiJ6RFEyTFhuQkE3Iiwicm9sZXMiOlsiYW5vbnltb3VzIl0sImV4cGlyZXNPbiI6IjIwMjAtMDQtMjdUMTY6NTM6NDkuNDExWiIsInVzZXJuYW1lIjoiRnVsbERpdmVyIzA2NjUxMDQ0In0.n3aSZDrySyWe9RFJLq1_cFfwWAv-0SmwX1Kuqh5yhhc",
};

const prod = {
  hostname: "api.fdvr.co",
  token:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiJNSTlHcWdaSDFfIiwiaWQiOiJNSTlHcWdaSDFfIiwicm9sZXMiOlsiYW5vbnltb3VzIl0sImV4cGlyZXNPbiI6IjIwMjAtMDQtMjdUMTY6NTI6MTkuMDEwWiIsInVzZXJuYW1lIjoiRnVsbERpdmVyIzA1MDg3NDg0In0.DPOqWfv9oAXI5eYWeqZlDdBhRkBa-KuOIYTQt2jsz8I",
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;

export default {
  ...config,
};
