import React from "react";
import { connect } from "react-redux";
import './posts.scss'
import { Button, Col, Container, Row } from "react-bootstrap";
import Article from "./Arcticle";
import ImageLoader from "../ImageLoader";
import { fetchFulldiveData } from "../../api";
import Spinner from "../Spinner";

const mapStateToProps = (state: any) => {
    return {
        data: state.data,
        loadingMore: state.loadingMore
    };
};

class Posts extends React.Component<any, any> {
    private readonly observer = new ImageLoader()

    chunkPosts(arr: any[], size: number = 3) {
        const a = [...arr]
        const res = [];

        while (a.length) {
            res.push(a.splice(0, size));
        }

        return res;
    }

    posts() {
        const data = this.props.data || []
        const rows = this.chunkPosts(data)
        return rows.map((cells: any, index: number) => {
            return (
                <Row key={index}>
                    {
                        cells.map((cell: any) => {
                            return (
                                <Col lg={4} xs={12} md={4} key={cell.id}>
                                    <Article data={cell}/>
                                </Col>
                            )

                        })
                    }
                </Row>
            )
        })
    }

    async loadMore() {
        await fetchFulldiveData(true)
    }

    componentDidMount() {
        this.observer.observe()
    }

    componentDidUpdate() {
        this.observer.observe()
    }

    render() {
        const items = this.posts()
        return (
            <Container className="posts-container">
                {items}
                {this.props.loadingMore === false &&
                (
                    <Row>
                        <Col md={12} xs={12} className="text-center">
                            <Button onClick={this.loadMore} variant="outline-primary">More</Button>{' '}
                        </Col>
                    </Row>
                )
                }
                {this.props.loadingMore === true && <Spinner/>}
            </Container>
        );
    }
}

export default connect(mapStateToProps, null)(Posts)
